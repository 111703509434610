<template>
  <div>
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="6" xl="4">
          <alert-icons />

          <v-container class="px-0 text-center text-sm-start">
            <!-- Step 1: Email and Password -->
            <v-form v-if="step < 2" @submit.prevent="login">
              <h2 class="gray400--text">{{ $t('helloStranger') }}</h2>
              <h1>{{ $t('yourTeamAwaits') }}</h1>
              <v-text-field
                v-model="email"
                :label="$t('email')"
                autofocus
                prepend-icon="fa-user"
                :hint="$t('loginEmailHelperText')"
                class="py-10"
              />

              <v-text-field
                v-model="password"
                :type="showPassword ? 'text' : 'password'"
                :label="$t('password')"
                autocomplete="on"
                prepend-icon="fa-lock"
              >
                <template #append>
                  <v-btn
                    :key="'showPassword=' + showPassword"
                    icon
                    color="primary"
                    @click="showPassword = !showPassword"
                  >
                    <v-icon v-if="!showPassword">fa-eye</v-icon>
                    <v-icon v-else>fa-eye-slash</v-icon>
                  </v-btn>
                </template>
              </v-text-field>

              <div class="d-flex py-3 flex-row-reverse">
                <v-btn :loading="loading" type="submit" color="primary" @click.prevent="login">{{
                  $t('login')
                }}</v-btn>
                <v-spacer />
                <v-btn text color="primary" to="/setNewPassword">{{ $t('forgotPassword') }}</v-btn>
              </div>
              <div class="d-flex">
                <v-icon class="mr-4"> fa fa-clock </v-icon>
                <v-checkbox v-model="extendedLogin" :label="$t('extendedLogin')"></v-checkbox>
              </div>
            </v-form>

            <v-form v-else-if="step < 3" @submit.prevent="verifyOtp">
              <h2>{{ $t('twoFactorAuth') }}</h2>
              <p>{{ $t('twoFactorAuthMsg') }}</p>
              <v-container class="d-flex justify-center">
                <v-icon color="primary" class="mr-2">fa-user-circle</v-icon
                ><strong> {{ email }}</strong>
              </v-container>
              <v-text-field
                ref="otpField"
                v-model="otp"
                :label="$t('typeYourCode')"
                autofocus
                prepend-icon="fa-user"
                class="pt-10"
                :hint="$t('twoFactorAuthHelperText')"
              />
              <div class="d-flex py-3">
                <v-spacer />
                <v-btn type="submit" color="primary" @click.prevent="verifyOtp">{{
                  $t('send')
                }}</v-btn>
              </div>
            </v-form>

            <div v-else class="text-center">
              <div class="d-flex justify-center pa-10">
                <v-icon color="primary" x-large>fa-check-circle</v-icon>
              </div>
              <p>{{ $t('loginSuccess') }}</p>
              <p>{{ $t('selectCompany') }}</p>
              <v-list tile>
                <template v-for="(customer, index) in customers">
                  <v-list-item
                    ref="customerItems"
                    :key="index"
                    link
                    @click="selectCustomer(customer)"
                  >
                    <v-list-item-content>
                      {{ customer.name }}
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
              <v-progress-linear :active="loading" indeterminate />
            </div>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import api from '@/util/api';
import auth from '@/util/auth';
import router from '@/router';
import getUserProfile from '@/util/getUserProfile';
import AlertIcons from '@/components/AlertIcons';
import messageDialog from '../../components/dialog/messageDialog';
import { translate as t } from '@/util/i18n';
import genericErrorMessage from '../../util/genericErrorMessage';

export default {
  name: 'Login',

  components: {
    AlertIcons,
  },

  data: () => ({
    loading: false,

    step: 1,
    showPassword: false,

    userId: 0,
    customers: [],

    maxLoginAttempts: 5,
    failedLoginAttempts: 0,
    maxBannedPeriodMinutes: 10,
    remainingBannedPeriodMinutes: 0,
    bannedPeriodInterval: null,

    email: '',
    password: '',
    otp: '',
    extendedLogin: false,
  }),

  beforeDestroy() {
    clearInterval(this.bannedPeriodInterval);
  },

  methods: {
    login() {
      if (this.remainingBannedPeriodMinutes > 0) {
        messageDialog(
          t('loginError'),
          `${t('loginMaxAttempts')} ${this.remainingBannedPeriodMinutes} ${t(
            'loginBannedPeriodRemaining',
          )}`,
          t('ok'),
          'error',
        ).open();
        return;
      }

      this.loading = true;
      api
        .login({
          username: this.email,
          password: this.password,
          extendedLogin: this.extendedLogin,
        })
        .then((res) => {
          if (res.userId) {
            auth.login();
            this.userName = res.userName;
            this.customers = res.customers;
            this.step = 3;
            this.focusCompanies();
          } else {
            this.step = 2;
          }
          this.userId = res.userId;
        })
        .catch((res) => {
          if (res.status) {
            this.failedLoginAttempts++;

            let loginErrorMsg = t('loginErrorMsg');
            if (this.failedLoginAttempts > this.maxLoginAttempts) {
              this.remainingBannedPeriodMinutes = this.maxBannedPeriodMinutes;
              this.bannedPeriodInterval = setInterval(() => {
                this.remainingBannedPeriodMinutes--;
                if (this.remainingBannedPeriodMinutes <= 0) {
                  clearInterval(this.bannedPeriodInterval);
                }
              }, 60000); // 60000 = 1 minute
              loginErrorMsg = `${t('loginMaxAttempts')} ${this.remainingBannedPeriodMinutes} ${t(
                'loginBannedPeriodRemaining',
              )}`;
              this.failedLoginAttempts = 0;
            }

            messageDialog(t('loginError'), loginErrorMsg, t('ok'), 'error').open();
          } else {
            genericErrorMessage(res);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    verifyOtp() {
      api
        .verifyTimeCode({
          username: this.email,
          password: this.password,
          extendedLogin: this.extendedLogin,
          code: this.otp,
        })
        .then((res) => {
          auth.login();
          this.userName = res.userName;
          this.customers = res.customers;
          this.step = 3;
          this.focusCompanies();
        })
        .catch(() => {
          messageDialog(t('loginError'), t('loginErrorOtpMsg'), t('ok'), 'error').open();
        });
    },

    selectCustomer(customer) {
      this.loading = true;
      auth.setCustomerId(customer.id);
      getUserProfile(true) // Reduce multiple calls to API by ensuring that getMe is called once before opening the rest of the application
        .then(() => {
          this.loading = false;
          if (this.$route.query && this.$route.query.redirect) {
            router.push(this.$route.query.redirect);
          } else {
            router.push('/');
          }
        });
    },

    focusCompanies() {
      setTimeout(() => {
        try {
          this.$refs.customerItems[0].$el.focus();
        } catch {
          // Do nothing
        }
      }, 100);
    },
  },
};
</script>
